import React, {useEffect, useState} from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { ReactSession } from 'react-client-session';


import {makeLogin} from './actions/SignIn'

export default function SignIn() {
    const InitialData = {
        email: '',
        password: '',
    }
    const [loginData, setLoginData] = useState(InitialData);
    const dispatch = useDispatch();
    ReactSession.setStoreType("localStorage");

    let handleChange = (event) => {
        let { name, value } = event.target;
        setLoginData({...loginData, [name]: value});
    }

    let handleLogin = () => {
        // console.log(loginData)
        dispatch(makeLogin(loginData)).then(response => {
            console.log(response);
            if(response.data.res == 0) {
                alert(response.data.msg);
            } else {
                ReactSession.set("login_status", response.data.res);
                ReactSession.set("email", loginData.email);
                ReactSession.set("url", response.data.url);
                ReactSession.set("id", response.data.url.split('/share/')[1]);
                alert(response.data.msg);
                window.location.href = response.data.url;
            }
        })
    }

    return (
        <>
            {/* <div class="col-lg-6 d-none d-lg-flex h-100vh">
                <div class="flex-grow-1 bg-login-image"></div>
            </div> */}
            <div class="col-lg-3"></div>
            <div class="col-lg-6 card mt-5">
                <div class="card-body p-5">
                    <div class="text-center">
                        <h4 class="text-dark mb-4">Welcome {loginData.email}!</h4>
                    </div>
                    <form class="user">
                        <div class="mb-3">
                            <input class="form-control form-control-user" type="email" id="email" onChange={handleChange} value={loginData.email} aria-describedby="emailHelp" placeholder="Enter Email Address..." name="email" />
                        </div>
                        <div class="mb-3">
                            <input class="form-control form-control-user" type="password" id="password" onChange={handleChange} value={loginData.password} placeholder="Password" name="password" />
                        </div>
                        {/* <div class="mb-3">
                            <div class="custom-control custom-checkbox small">
                                <div class="form-check">
                                    <input class="form-check-input custom-control-input" type="checkbox" id="formCheck-1" />
                                    <label class="form-check-label custom-control-label" for="formCheck-1">Remember Me</label>
                                </div>
                            </div>
                        </div> */}
                        <button class="btn btn-primary d-block btn-user w-100" type="button" onClick={handleLogin}>Login</button>
                        {/* <hr>
                        <a class="btn btn-primary d-block btn-google btn-user w-100 mb-2" role="button">
                            <i class="fab fa-google"></i>
                            &nbsp; Login with Google
                        </a>
                        <a class="btn btn-primary d-block btn-facebook btn-user w-100" role="button">
                            <i class="fab fa-facebook-f"></i>
                            &nbsp; Login with Facebook
                        </a> */}
                        <hr />
                    </form>
                    {/* <div class="text-center"><a class="small" href="forgot-password.html">Forgot Password?</a></div> */}
                    {/* <div class="text-center"><a class="small" href="register.html">Create an Account!</a></div> */}
                </div>
            </div>
        </>
    )
}
