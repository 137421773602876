import {
    CREATE_ARCSUPPORTTICKETS,
    RETRIEVE_ARCSUPPORTTICKETS,
    RETRIEVESINGLE_ARCSUPPORTTICKETS,
    UPDATE_ARCSUPPORTTICKETS,
    DELETE_ARCSUPPORTTICKETS,
  } from "../actions/type";
  const initialState = [];
  function ARCSupportTicketsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_ARCSUPPORTTICKETS:
        return {data: state.data, payload};
      case RETRIEVE_ARCSUPPORTTICKETS:
        // console.log(payload)
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_ARCSUPPORTTICKETS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_ARCSUPPORTTICKETS:
        return {data: state.data, payload};
      case DELETE_ARCSUPPORTTICKETS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {ARCSupportTicketsReducer};