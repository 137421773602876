import {
    RETRIEVE_SHAREABLE_LINKS,
} from "./type";
import Architecture from "../service/architecture";
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await Architecture.fetchList(data);
        dispatch({
            type: RETRIEVE_SHAREABLE_LINKS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};