import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import avatar1 from '../../assets/avatars/avatar1.jpeg'
import avatar4 from '../../assets/avatars/avatar4.jpeg'
import avatar2 from '../../assets/avatars/avatar2.jpeg'
import avatar3 from '../../assets/avatars/avatar3.jpeg'
import avatar5 from '../../assets/avatars/avatar5.jpeg'

import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import { Loader, Placeholder } from 'rsuite';

import SJFJobs_add from './sjfJobs_add';

import {
    retrieve, trash,
} from "./actions/sjfJobs";

function SocialjobfinderJobs() {
    const jobs_data = useSelector(state => state.SJFJobsReducer, shallowEqual);
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        id: '', 
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [page, setPage] = useState(0);
    const [record_length, setRecordLength] = useState(-1);
    const [showTrashAlert, setShowTrashAlert] = useState({show: false});

    const dispatch = useDispatch();

    useEffect(() => {
        let data = {
            limit: -1,
            last_job_id: '',
            first_job_id: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }, []);

    let handleChange = (e) => {
        if(e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_job_id: '',
            first_job_id: jobs_data.data.first_job_id,
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page?page - 1:page)
            setLoading(false)
        });
    }
    let handleNext = () => {
        setLoading(true);
        // console.log(
        //     parseInt((
        //         jobs_data && 
        //         jobs_data.data && 
        //         jobs_data.data.total ? jobs_data.data.total:1
        //     ) / record_length), page);
        setPage((
            page <= (
                parseInt((
                    jobs_data && 
                    jobs_data.data && 
                    jobs_data.data.total ? jobs_data.data.total:1
                ) / record_length) + 1
            ))
            ?page + 1
            :page
        );
        let data = {
            limit: record_length,
            last_job_id: jobs_data.data.last_job_id,
            first_job_id: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }

    let handleEdit = (record) => {
        console.log(record)
        setSingleData(
            {
                job_id: record.job_id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({uid: uid, show: !showTrashAlert.show});
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            uid: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                limit: record_length,
                last_job_id: '',
                first_job_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }




    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4 mt-4">
                        <h3 className="mb-0 fg-theme">Jobs</h3>
                        <div className='text-right'>
                        </div>
                    </div>
                    <div className="card shadow">
                        {/* <div className="card-header py-3">
                            <p className="text-primary m-0 fw-bold">Job List</p>
                        </div> */}
                        <div className="card-body">
                            {/* <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                                </div>
                            </div> */}
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Company Name</th>
                                            <th>Service</th>
                                            <th>Sector</th>
                                            <th>Role</th>
                                            <th>Salary</th>
                                            <th>Hiring</th>
                                            <th>Posted On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            jobs_data &&
                                                jobs_data.data &&
                                                // jobs_data.data.length > 0 && 
                                                jobs_data.data.jobs &&
                                                jobs_data.data.jobs.length > 0 ?
                                                jobs_data.data.jobs.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{val.company_name}&nbsp; <sup><span className='badge bg-dark'><i className='fa fa-info'></i></span></sup></td>
                                                            <td>{val.service}</td>
                                                            <td>{val.sector}</td>
                                                            <td>{val.role}</td>
                                                            <td>{val.salary}</td>
                                                            <td>{val.hiring}</td>
                                                            <td>{val.posted_on}</td>
                                                        </tr>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {jobs_data && jobs_data.data && jobs_data.data.total?jobs_data.data.total:0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            <li className={page?"page-item":"page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                            <li className={(page <= (parseInt((jobs_data && jobs_data.data && jobs_data.data.total?jobs_data.data.total:1) / record_length) - 1))?"page-item":"page-item disabled"}  onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <SweetAlert
                        show={showTrashAlert.show}
                        title=""
                        text="Are you sure you want to permanently delete this record?"
                        onCancel={() => handleShowTrashAlert(false)}
                        showCancelButton
                        onConfirm={handleAlertResponse}
                    />
                </>
            }
        </>
    )
}


export default SocialjobfinderJobs;