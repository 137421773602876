import {
    MAKE_ENDUSER_LOGIN,
} from "./type";
import SignIn from "../service/SignIn";
export const makeLogin = (data) => async (dispatch) => {
    try {
        const res = await SignIn.makeLogin(data);
        dispatch({
            type: MAKE_ENDUSER_LOGIN,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};