import {
    CREATE_SJFGENERAL,
    RETRIEVE_SJFGENERAL,
    RETRIEVESINGLE_SJFGENERAL,
    UPDATE_SJFGENERAL,
    DELETE_SJFGENERAL,
  } from "../actions/type";
  const initialState = [];
  function SJFGeneralReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFGENERAL:
        return {data: state.data, payload};
      case RETRIEVE_SJFGENERAL:
        // console.log(payload)
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFGENERAL:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFGENERAL:
        return {data: state.data, payload};
      case DELETE_SJFGENERAL:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFGeneralReducer};