import http from "../../../http-axios.function";


const makeLogin = (data) => {
    return http.post(`/super_admin/shareable_links/login`, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};



const SignIn = {
    makeLogin,
};
export default SignIn;