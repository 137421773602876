import {
    CREATE_SJFJOBS,
    RETRIEVE_SJFJOBS,
    RETRIEVESINGLE_SJFJOBS,
    UPDATE_SJFJOBS,
    DELETE_SJFJOBS,
  } from "../actions/type";
  const initialState = [];
  function SJFJobsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFJOBS:
        return {data: state.data, payload};
      case RETRIEVE_SJFJOBS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFJOBS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFJOBS:
        return {data: state.data, payload};
      case DELETE_SJFJOBS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFJobsReducer};