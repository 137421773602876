import {
    CREATE_SJFFAQS,
    RETRIEVE_SJFFAQS,
    RETRIEVESINGLE_SJFFAQS,
    UPDATE_SJFFAQS,
    DELETE_SJFFAQS,
  } from "../actions/type";
  const initialState = [];
  function SJFFAQsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case CREATE_SJFFAQS:
        return {data: state.data, payload};
      case RETRIEVE_SJFFAQS:
        // console.log(payload)
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      case RETRIEVESINGLE_SJFFAQS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state.data, payload};
      case UPDATE_SJFFAQS:
        return {data: state.data, payload};
      case DELETE_SJFFAQS:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SJFFAQsReducer};