


// import { SigninpageReducer } from "./client/signinpage/reducer/signinpage";

import { ARCServersReducer } from "./client/Architecture/architectureServers/reducer/arcServers"
import { ARCSupportTicketsReducer } from "./client/Architecture/architectureSupportTickets/reducer/arcSupportTickets"

import { SJFApplicantsReducer } from "./client/SocialJobFinder/reducer/sjfApplicants"
import { SJFCompaniesReducer } from "./client/SocialJobFinder/reducer/sjfCompanies"
import { SJFFAQsReducer } from "./client/SocialJobFinder/reducer/sjfFAQs"
import { SJFGeneralReducer } from "./client/SocialJobFinder/reducer/sjfGeneral"
import { SJFJobsReducer } from "./client/SocialJobFinder/reducer/sjfJobs"
import { SJFMeetingsReducer } from "./client/SocialJobFinder/reducer/sjfMeetings"
import { SJFMessagesReducer } from "./client/SocialJobFinder/reducer/sjfMessages"
import { SJFRolesReducer } from "./client/SocialJobFinder/reducer/sjfRoles"
import { SJFSectorsReducer } from "./client/SocialJobFinder/reducer/sjfSectors"
import { SJFServicesReducer } from "./client/SocialJobFinder/reducer/sjfServices"
import { SJFSupportTicketsReducer } from "./client/SocialJobFinder/reducer/sjfSupportTickets"

import { SignInReducer } from "./client/SignIn/reducer/SignIn"

import { ArchitectureShareableLinkReducer } from "./client/Architecture/Architecture/reducer/architecture"

import {combineReducers} from "redux";


const rootReducer = combineReducers({
    ArchitectureShareableLinkReducer: ArchitectureShareableLinkReducer,
    SignInReducer: SignInReducer,
    ARCSupportTicketsReducer: ARCSupportTicketsReducer,
    ARCServersReducer: ARCServersReducer,
    SJFApplicantsReducer: SJFApplicantsReducer,
    SJFCompaniesReducer: SJFCompaniesReducer,
    SJFFAQsReducer: SJFFAQsReducer,
    SJFGeneralReducer: SJFGeneralReducer,
    SJFJobsReducer: SJFJobsReducer,
    SJFMeetingsReducer: SJFMeetingsReducer,
    SJFMessagesReducer: SJFMessagesReducer,
    SJFRolesReducer: SJFRolesReducer,
    SJFSectorsReducer: SJFSectorsReducer,
    SJFServicesReducer: SJFServicesReducer,
    SJFSupportTicketsReducer: SJFSupportTicketsReducer,
});

export default rootReducer;
