import {
    MAKE_ENDUSER_LOGIN,
  } from "../actions/type";
  const initialState = [];
  function SignInReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case MAKE_ENDUSER_LOGIN:
        return {data: state.data, payload};
      default:
        return state;
    }
  };
  export {SignInReducer};