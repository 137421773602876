import http from "../../../../http-axios.function";

const fetchList = (data) => {
    return http.post(`/super_admin/shareable_links/fetch`, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }, data
    });
};

const Architecture = {
    fetchList,
};

export default Architecture;