import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import avatar1 from '../../assets/avatars/avatar1.jpeg'
import avatar4 from '../../assets/avatars/avatar4.jpeg'
import avatar2 from '../../assets/avatars/avatar2.jpeg'
import avatar3 from '../../assets/avatars/avatar3.jpeg'
import avatar5 from '../../assets/avatars/avatar5.jpeg'

import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';


import { Loader, Placeholder } from 'rsuite';

import SJFCompanies_add from './sjfCompanies_add';

import {
  retrieve, trash
} from "./actions/sjfCompanies";

export default function SocialjobfinderCompanies() {
    const company_data = useSelector(state => state.SJFCompaniesReducer, shallowEqual);
    const [loading, setLoading] = useState(true);
    const initial_single_data = {
        id: '', 
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [page, setPage] = useState(0);
    const [record_length, setRecordLength] = useState(-1);
    const [showTrashAlert, setShowTrashAlert] = useState({show: false});
    // console.log(showTrashAlert)

    const dispatch = useDispatch();
  
    useEffect(() => {
      let data = {
        limit: -1,
        last_uid: '',
        first_uid: '',
      };
      dispatch(retrieve(data)).then(() => setLoading(false))
    }, []);

    let handleChange = (e) => {
        if(e.target.name == 'record_length')
            setRecordLength(e.target.value);
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_uid: '',
            first_uid: company_data.data.first_uid,
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page?page - 1:page)
            setLoading(false)
        });
    }
    let handleNext = () => {
        setLoading(true);
        // console.log(
        //     parseInt((
        //         company_data && 
        //         company_data.data && 
        //         company_data.data.total ? company_data.data.total:1
        //     ) / record_length), page);
        setPage((
            page <= (
                parseInt((
                    company_data && 
                    company_data.data && 
                    company_data.data.total ? company_data.data.total:1
                ) / record_length) + 1
            ))
            ?page + 1
            :page
        );
        let data = {
            limit: record_length,
            last_uid: company_data.data.last_uid,
            first_uid: '',
        };
        dispatch(retrieve(data)).then(() => setLoading(false));
    }

    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({uid: uid, show: !showTrashAlert.show});
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            uid: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            let data = {
                limit: record_length,
                last_uid: '',
                first_uid: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({show: !showTrashAlert.show});
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }



    return (
        <>
        {loading ?
            <div>
                <Placeholder.Paragraph rows={8} />
                <Loader center content="loading" />
            </div>
        :
            <>
                <div className="d-sm-flex justify-content-between align-items-center mb-4 mt-4">
                    <h3 className="mb-0 fg-theme">Companies</h3>
                    <div className='text-right'>
                    </div>
                </div>
                <div className="card shadow">
                    {/* <div className="card-header py-3">
                        <p className="text-primary m-0 fw-bold">Company List</p>
                    </div> */}
                    <div className="card-body">
                        {/* <div className="row">
                            <div className="col-md-6 text-nowrap">
                                <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                    <label className="form-label">Show&nbsp;
                                        <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                            </div>
                        </div> */}
                        <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                            <table className="table my-0" id="dataTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Sector</th>
                                        <th>Last Login</th>
                                        <th>Access Blocked</th>
                                        <th>Subscription</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        company_data && 
                                        company_data.data && 
                                        company_data.data.companies && 
                                        company_data.data.companies.length > 0 ?
                                        company_data.data.companies.map((val, index) => {
                                            return(
                                                <tr>
                                                    <td>{val.name}</td>
                                                    <td>{val.email}</td>
                                                    <td>{val.sector}</td>
                                                    <td>{val.last_login}</td>
                                                    <td>{val.access}</td>
                                                    <td>{val.subscription}</td>
                                                </tr>
                                            )
                                        })
                                        :''
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-6 align-self-center">
                                <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {company_data && company_data.data && company_data.data.total?company_data.data.total:0}</p>
                            </div>
                            <div className="col-md-6">
                                <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul className="pagination">
                                        <li className={page?"page-item":"page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                        <li className={(page <= (parseInt((company_data && company_data.data && company_data.data.total?company_data.data.total:1) / record_length) - 1))?"page-item":"page-item disabled"}  onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                    </div>
                </div>
                <SweetAlert
                    show={showTrashAlert.show}
                    title=""
                    text="Are you sure you want to permanently delete this record?"
                    onCancel={() => handleShowTrashAlert(false)}
                    showCancelButton
                    onConfirm={handleAlertResponse}
                />
            </>
        }
        </>
    )
}
