import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { ReactSession } from 'react-client-session';

import ARCServerList from '../architectureServers/architectureServers'
import ARCSupportTickets from '../architectureSupportTickets/architectureSupportTickets'
import ARCTypesOfService from '../architectureSupportTickets/architectureSupportTickets'
import ARCGovernments from '../Government/Government'
import ARCRegulators from '../Regulators/Regulators'
import ARCAttorneys from '../Attorneys/Attorneys'


import SJFCompanies from '../../SocialJobFinder/socialjobfinderCompanies'
import SJFApplicants from '../../SocialJobFinder/socialjobfinderApplicants'
import SJFFAQs from '../../SocialJobFinder/socialjobfinderFAQs'
// import SJFGeneral from '../../SocialJobFinder/socialjobfinderGeneral'
import SJFJobs from '../../SocialJobFinder/socialjobfinderJobs'
import SJFMeetings from '../../SocialJobFinder/socialjobfinderMeetings'
// import SJFMessages from '../../SocialJobFinder/socialjobfinderMessages'
// import SJFRoles from '../../SocialJobFinder/socialjobfinderRoles'
import SJFSectors from '../../SocialJobFinder/socialjobfinderSectors'
// import SJFServices from '../../SocialJobFinder/socialjobfinderServices'
import SJFSupportTickets from '../../SocialJobFinder/socialjobfinderSupportTickets'

import { retrieve } from './actions/architecture'

export default function Architecture() {
  const [dataShareableLinks, setDataShareableLinks] = useState([]);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);

  const dispatch = useDispatch();
  ReactSession.setStoreType("localStorage");

  let id = ReactSession.get('id')
  let email = ReactSession.get('email')
  let flag = 0;
  

  useEffect(() => {
    // getLocation()
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition)
    }
  }, []);

  
  function showPosition(position) {
    setLongitude(position.coords.longitude)
    setLatitude(position.coords.latitude)
    if(flag == 0) {
      flag = 1;
      let data = {
        email: email,
        id: id,
        coordinates: position.coords.longitude + ',' + position.coords.latitude,
        ip_address: window.location.host,
        browser_name: navigator.userAgent,
      };
      dispatch(retrieve(data)).then((response) => { setDataShareableLinks(response); });
    }
  }
  // const getIP = async () => {
  //   const { RTCPeerConnection } = window;
  //   const pc = new RTCPeerConnection({ iceServers: [] });
  //   pc.createDataChannel('');
  //   pc.createOffer().then(pc.setLocalDescription.bind(pc));
  //   pc.onicecandidate = (ice) => {
  //     if (!ice || !ice.candidate || !ice.candidate.candidate) return;
  //     const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
  //     const ipMatch = ice.candidate.candidate.match(ipRegex);
  //     const ip = ipMatch && ipMatch[1];
  //     console.log(ip);
  //     pc.onicecandidate = () => {};
  //   };
  // };

  let formatDate = (datex) => {
    const date = new Date(datex);
    let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const yyyy = date.getFullYear();
    let mm = monthArr[date.getMonth()]; // Months start at 0!
    // let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = mm + ' ' + dd + ', ' + yyyy;
    return formattedToday;
  }
  // console.log(dataShareableLinks)
  return (
    <>
      <div className="container-fluid mt-4 mb-4">
        <h3 className="fg-theme d-block mb-0">Dashboard</h3>
        <hr />
        <div className='container-fluid p-0 d-block'>
          <label>Email : {email}</label><br />
          <label>Validity : {dataShareableLinks && dataShareableLinks.validity ? formatDate(dataShareableLinks.validity) : 'N/A'}</label>
        </div>
        {/* <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="#"><i className="fas fa-download fa-sm"></i>&nbsp;Generate Report</a> */}
      </div>
      {/* <ARCServerList />
        <ARCSupportTickets />
        <ARCTypesOfService />
        <ARCGovernments />
        <ARCRegulators />
        <ARCAttorneys /> */}


      <div className="container-fluid mt-4 mb-4">


        {dataShareableLinks && dataShareableLinks && dataShareableLinks.sjf_tags && dataShareableLinks.sjf_tags.includes("companies") ? <SJFCompanies /> : ''}
        {dataShareableLinks && dataShareableLinks && dataShareableLinks.sjf_tags && dataShareableLinks.sjf_tags.includes("applicants") ? <SJFApplicants /> : ''}


        {/* <SJFFAQs /> */}
        {/* <SJFGeneral /> */}
        {dataShareableLinks && dataShareableLinks && dataShareableLinks.sjf_tags && dataShareableLinks.sjf_tags.includes("jobs") ? <SJFJobs /> : ''}
        {/* <SJFMeetings /> */}
        {/* <SJFMessages /> */}
        {/* <SJFRoles /> */}
        {dataShareableLinks && dataShareableLinks && dataShareableLinks.sjf_tags && dataShareableLinks.sjf_tags.includes("sectors") ? <SJFSectors /> : ''}
        {/* <SJFServices /> */}
        {dataShareableLinks && dataShareableLinks && dataShareableLinks.sjf_tags && dataShareableLinks.sjf_tags.includes("support_tickets") ? <SJFSupportTickets /> : ''}

      </div>

    </>
  )
}

