import {
    // FILE_UPLOAD,
    CREATE_SJFJOBS,
    RETRIEVE_SJFJOBS,
    RETRIEVE_SJFROLES,
    RETRIEVE_SJFSERVICES,
    RETRIEVE_SJFSECTORS,
    RETRIEVESINGLE_SJFJOBS,
    UPDATE_SJFJOBS,
    DELETE_SJFJOBS,
} from "./type";
import SJFJobs from "../service/sjfJobs";
import SJFServices from "../service/sjfServices";
import SJFRoles from "../service/sjfRoles";
import SJFSectors from "../service/sjfSectors";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await SJFJobs.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await SJFJobs.create(data);
        dispatch({
            type: CREATE_SJFJOBS,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await SJFJobs.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFJOBS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await SJFJobs.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_SJFJOBS,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
    }
};
export const retrieveService = (data) => async (dispatch) => {
    try {
        const res = await SJFServices.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFSERVICES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveRole = (data) => async (dispatch) => {
    try {
        const res = await SJFRoles.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFROLES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSector = (data) => async (dispatch) => {
    try {
        const res = await SJFSectors.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFSECTORS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await SJFJobs.update(data);
        dispatch({
            type: UPDATE_SJFJOBS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await SJFJobs.changeStatus(data);
//         dispatch({
//             type: UPDATE_SJFJOBS,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await SJFJobs.trash(data);
        dispatch({
            type: DELETE_SJFJOBS,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};