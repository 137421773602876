import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Architecture from './client/Architecture/Architecture/architecture';


import Wrapper from './wrapper'

import './App.css';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import SignIn from './client/SignIn/SignIn';
import { ReactSession } from 'react-client-session';

const App = () => {
    ReactSession.setStoreType("localStorage");
    let login_status = ReactSession.get("login_status");

    return(
    <>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/share/:id" element={login_status?<Wrapper><Architecture /></Wrapper>:<SignIn />} />
      </Routes>
      <ToastContainer
      position="bottom-right"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      </BrowserRouter>
    </>
  )
}


export default App;