import {
    RETRIEVE_SHAREABLE_LINKS,
  } from "../actions/type";
  const initialState = [];
  function ArchitectureShareableLinkReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RETRIEVE_SHAREABLE_LINKS:
        state = (payload.error == 1)?initialState:payload;
        return {data: state, payload};
      default:
        return state;
    }
  };
  export {ArchitectureShareableLinkReducer};