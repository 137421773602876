import {
    // FILE_UPLOAD,
    CREATE_SJFCOMPANIES,
    RETRIEVE_SJFCOMPANIES,
    RETRIEVESINGLE_SJFCOMPANIES,
    UPDATE_SJFCOMPANIES,
    DELETE_SJFCOMPANIES,
    RETRIEVE_SJFSERVICES,
    RETRIEVE_SJFSECTORS,
} from "./type";
import SJFCompanies from "../service/sjfCompanies";
import SJFSectors from "../service/sjfSectors";
import SJFServices from "../service/sjfServices";
import ARCServers from "../../Architecture/architectureServers/service/arcServers";
import { RETRIEVE_ARCHITECTURESERVER } from "../../Architecture/architectureServers/actions/type";
// export const fileUpload = (data) => async (dispatch) => {
//   try {
//     const res = await SJFCompanies.fileUpload(data);
//     dispatch({
//       type: FILE_UPLOAD,
//       payload: res.data.data,
//     });
//     return Promise.resolve(res.data);
//   } catch (err) {
//     return Promise.reject(err);
//   }
// };
export const create = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.create(data);
        dispatch({
            type: CREATE_SJFCOMPANIES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const createARC = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.createARC(data);
        dispatch({
            type: CREATE_SJFCOMPANIES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const createSJF = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.createSJF(data);
        dispatch({
            type: CREATE_SJFCOMPANIES,
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
export const retrieveServers = (data) => async (dispatch) => {
    try {
        const res = await ARCServers.fetchList(data);
        // dispatch({
        //     type: RETRIEVE_ARCHITECTURESERVER,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieve = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.fetchList(data);
        console.log(res);
        dispatch({
            type: RETRIEVE_SJFCOMPANIES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveSingle = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.fetchSingle(data);
        // dispatch({
        //     type: RETRIEVESINGLE_SJFCOMPANIES,
        //     status: '',
        //     response: {},
        //     payload: res.data.data,
        // });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const retrieveService = (data) => async (dispatch) => {
    try {
        const res = await SJFServices.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFSERVICES,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};

export const retrieveSector = (data) => async (dispatch) => {
    try {
        const res = await SJFSectors.fetchList(data);
        dispatch({
            type: RETRIEVE_SJFSECTORS,
            status: '',
            response: {},
            payload: res.data.data,
        });
        return Promise.resolve(res.data.data);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};
export const update = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.update(data);
        dispatch({
            type: UPDATE_SJFCOMPANIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};
// export const changeStatus = (data) => async (dispatch) => {
//     try {
//         const res = await SJFCompanies.changeStatus(data);
//         dispatch({
//             type: UPDATE_SJFCOMPANIES,
//             status: '',
//             response: {},
//             payload: res.data,
//         });
//         return Promise.resolve(res.data);
//     } catch (err) {
//         return Promise.reject(err);
//     }
// };
export const trash = (data) => async (dispatch) => {
    try {
        const res = await SJFCompanies.trash(data);
        dispatch({
            type: DELETE_SJFCOMPANIES,
            status: '',
            response: {},
            payload: res.data,
        });
        return Promise.resolve(res.data);
    } catch (err) {
        return Promise.reject(err);
    }
};